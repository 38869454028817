import { Button } from "@/components/ui/Button";
import { Input } from "@/components/ui/Input";
import { useAuth, withoutAuth } from "@/contexts/authContext";
import getValidationErrors from "@/lib/getYupValidationErrors";
import { LoaderCircle } from "lucide-react";
import { useState } from "react";
import * as Yup from "yup";


interface FormProps {
  login: string;
  password: string;
}

const Schema = Yup.object().shape({
  login: Yup.string().required("Login obrigatório"),
  password: Yup.string()
    .required("Senha obrigatória"),
});

const Login = () => {
  const { handleLogin, company } = useAuth();

  const [login, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState({} as FormProps);
  const [loading, setLoading] = useState(false);

  const contactSupport = () => {
    if (!company?.contact) return;
    window.open(`https://api.whatsapp.com/send/?phone=${company?.contact}`, "_blank");
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError({} as FormProps);
    try {
      const values = { login, password };

      await Schema.validate(values, { abortEarly: false });

      await handleLogin(values);
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error)
      if (error instanceof Yup.ValidationError) {
        const newError = getValidationErrors(error);
        setError(newError as FormProps);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <div className="w-full max-w-md">
        <div className="flex flex-col items-center">
          <img src={company?.imageUrl} alt={company?.companyName} className="w-32 h-32 rounded-lg object-contain" />
        </div>
        <div className="mt-8 space-y-6 mb-8">
          <Input
            type="text"
            placeholder="Login"
            className="w-full"
            onChange={(e) => setEmail(e.target.value)}
            error={error.login}
          />
          <Input
            type="password"
            placeholder="Password"
            className="w-full"
            onChange={(e) => setPassword(e.target.value)}
            error={error.password}
          />
          <Button className="w-full" size="lg" disabled={loading} onClick={handleSubmit}>
            {loading ? <LoaderCircle className="animate-spin" /> : "Entrar"}
          </Button>
        </div>

        <p className="text-sm text-text text-center mt-20">Está com problemas para entrar? Entre em contato com o suporte.</p>
        <Button variant="link" className="w-full" onClick={contactSupport}>Falar com o suporte</Button>

      </div>
    </div>
  );
}

export default withoutAuth(Login);