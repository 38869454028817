import { useState } from "react";
import { Button } from "@/components/ui/Button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/Sheet";
import { Menu } from "lucide-react";
import { useAuth } from "@/contexts/authContext";

export default function Header() {
  const { company, session, logout } = useAuth();
  const [open, setOpen] = useState(false);

  const navigateToWhatsapp = () => {
    window.open(`https://api.whatsapp.com/send/?phone=${company?.contact}`, "_blank");
  };

  return (
    <header className="py-2 px-4 sm:px-6 lg:px-8 bg-card shadow-sm">
      <div className="xl:container mx-auto flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <img
            src={company?.imageUrl}
            alt={company?.companyName}
            className="w-20 h-20 rounded-md object-contain"
          />
          <span className="text-lg">
            Olá, <span className="text-primary font-bold">{session?.client.name.split(' ')[0]}</span>
          </span>
        </div>

        {/* Desktop navigation */}
        <nav className="hidden md:flex space-x-4">
          <Button variant="ghost" onClick={navigateToWhatsapp}>
            Suporte
          </Button>
          <Button variant="link" className="text-red" onClick={logout}>Sair</Button>
        </nav>

        {/* Mobile navigation */}
        <Sheet open={open} onOpenChange={setOpen}>
          <SheetTrigger asChild>
            <Button size="icon" className="md:hidden bg-background">
              <Menu size={24} className="text-text" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="right">
            <nav className="flex flex-col space-y-4 mt-6">
              <Button variant="ghost" onClick={navigateToWhatsapp}>
                Suporte
              </Button>
              <Button variant="link" className="text-red" onClick={logout}>Sair</Button>
            </nav>
          </SheetContent>
        </Sheet>
      </div>
    </header>
  );
}
