import { Lead } from "@/dtos/leads";
import { MessageCircle, Send, X } from "lucide-react";
import OpenAI from "openai";
import React, { useEffect, useState, useRef } from "react";
import { Button } from "./ui/Button";
import { Input } from "./ui/Input";
import { ScrollArea } from "./ui/ScrollArea";
import { useAuth } from "@/contexts/authContext";
import api from "@/services/api";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogOverlay
} from "@/components/ui/Dialog";

interface ChatProps {
  lead: Lead;
}

const Chat: React.FC<ChatProps> = ({ lead }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { client } = useAuth();
  const [messages, setMessages] = useState<
    OpenAI.Beta.Threads.Messages.Message[]
  >([]);
  const [message, setMessage] = useState("");
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const isScrolledToBottomRef = useRef(true);

  const loadMessages = async () => {
    const openai = new OpenAI({
      apiKey: client?.openAiSecretKey,
      dangerouslyAllowBrowser: true,
    });

    const threadMessages = await openai.beta.threads.messages.list(
      lead.threadId, 
      {
        limit: 50,
      }
    );
    
    setMessages(threadMessages.data);
   
  };

  const handleSendMessage = async () => {
    if (!message.trim()) return;
    
    setMessage("");
    await api.post(`/phones/${client?.phoneId}/leads/${lead._id}`, {
      //message: `*${client?.name}*\n${message}`,
      message: message,
    });

    isScrolledToBottomRef.current = true;
    loadMessages();
  };

  useEffect(() => {
    if (isOpen && client?.openAiSecretKey && lead.threadId) {
      loadMessages();
    }
  }, [client?.openAiSecretKey, lead, isOpen]);

  useEffect(() => {
    if (isOpen && messages.length > 0 && isScrolledToBottomRef.current) {
      const viewport = scrollAreaRef.current?.querySelector('[data-radix-scroll-area-viewport]');
      if (viewport) {
        viewport.scrollTo({
          top: viewport.scrollHeight,
          behavior: 'smooth'
        });
      }
    }
  }, [messages, isOpen]);


  return (
    <>
      <Button variant="ghost" onClick={() => setIsOpen(true)}>
        <MessageCircle size={16} />
      </Button>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogOverlay/>
        <DialogContent className="fixed bottom-4 right-4 left-4 md:left-auto md:w-[500px] p-0 rounded-lg border border-border overflow-hidden shadow-lg">
          <div className="flex flex-col h-full bg-background">
            <DialogHeader className="p-4 border-b border-border">
              <div className="flex justify-between items-center">
                <DialogTitle>{lead.name}</DialogTitle>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setIsOpen(false)}
                  aria-label="Fechar chat"
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
            </DialogHeader>

            <ScrollArea 
              className="h-[500px]"
              ref={scrollAreaRef}
            >
              <div className="px-4 flex flex-col-reverse">
                {messages.map((message) => (
                  <div key={message.id} className="my-2">
                    {message.role === "user" ? (
                      <div className="bg-border w-[80%] text-primary-foreground p-2 rounded-lg">
                        {message.content[0].type === "text"
                          ? message.content[0].text.value
                          : "Imagem"}
                      </div>
                    ) : (
                      <div className="bg-card w-[80%] text-secondary-foreground p-2 rounded-lg ml-auto">
                        {message.content[0].type === "text"
                          ? message.content[0].text.value
                          : "Imagem"}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </ScrollArea>

            <div className="p-4 border-t border-border mt-auto">
              <div className="flex items-center space-x-2">
                <Input
                  placeholder="Digite sua mensagem..."  
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSendMessage();
                    }
                  }}
                />
                <Button className="h-12" onClick={handleSendMessage}>
                  Enviar
                  <Send size={16} className="ml-2" />
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Chat;
