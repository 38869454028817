import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  ReactNode,
} from "react";
import api from "../services/api";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { getSavedSession, LOCAL_STORAGE_SESSION_KEY, saveSession } from "@/lib/verifyToken";
import { getSubdomain } from "@/lib/utils";

export interface SessionResponse {
  token: string;
  client: {
    name: string;
    login: string;
  };
}

export interface CompanyResponse {
  companyName: string;
  nickname: string;
  contact: string;
  imageUrl: string;
  color: {
    primary: string;
    secondary: string;
  };
}

interface LoginProps {
  login: string;
  password: string;
}

interface AuthContextData {
  session?: SessionResponse;
  company?: CompanyResponse;
  handleLogin: (data: LoginProps) => Promise<void>;
  logout: () => Promise<void>;
  loadingCompany: boolean;
  companyError: boolean;
  loadingClient: boolean;
  client?: Client;
}

interface Client {
  userId: string;
  phoneId: string;
  phone: string;
  name: string;
  login: string;
  openAiAssistantId: string
  openAiSecretKey: string
}

const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [loadingCompany, setLoadingCompany] = useState(true);
  const [companyError, setCompanyError] = useState(false);

  const [session, setSession] = useState<SessionResponse>();
  const [company, setCompany] = useState<CompanyResponse>();
  const [client, setClient] = useState<Client>();
  const [loadingClient, setLoadingClient] = useState(false);

  const getCompanyData = useCallback(async () => {
    const subdomain = getSubdomain();

    try {
      const { data } = await api.get(`/white-label/company`, {
        params: {
          nickname: subdomain,
        },
      });

      document.documentElement.style.setProperty(
        "--primary",
        data.color.primary
      );
      document.documentElement.style.setProperty(
        "--secondary",
        data.color.secondary
      );

      // Set favicon dynamically
      const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
      if (link) {
        link.href = data.imageUrl;
      } else {
        const newLink = document.createElement('link');
        newLink.rel = 'icon';
        newLink.href = data.imageUrl;
        document.head.appendChild(newLink);
      }

      // Set page title dynamically
      document.title = data.companyName + ' | Bot controle';

      setCompany(data);
    } catch (err) {
      setCompanyError(true);
    } finally {
      setLoadingCompany(false);
    }
  }, []);

  const getClientData = useCallback(async (login: string) => {
    setLoadingClient(true);

    try {
      const { data } = await api.get(`/profile/client`, {
        params: {
          login,
        },
      });

      setClient(data);
    } catch (err) {
      setSession(undefined);
      localStorage.removeItem(LOCAL_STORAGE_SESSION_KEY);
      toast.error("Sessão expirada");
    } finally {
      setLoadingClient(false);
    }
  }, []);

  const handleLogin = useCallback(
    async (data: LoginProps) => {
      try {
        const response = await api.post<SessionResponse>("/sessions/clients", {
          ...data,
          nickname: company?.nickname,
        });
        saveSession(response.data);
        setSession(response.data);
        getClientData(response.data.client.login);
      } catch (err) {
        toast.error("Email ou senha inválidos");
      }
    },
    [company?.nickname, getClientData]
  );

  const logout = useCallback(async () => {
    setSession(undefined);
    localStorage.removeItem(LOCAL_STORAGE_SESSION_KEY);
  }, []);

  useEffect(() => {
    getCompanyData();
  }, [getCompanyData])

  useEffect(() => {
    console.log('useEffect');
    
    const newSession = getSavedSession();

    if (newSession?.token) {
      getClientData(newSession?.client.login || "");
      setSession(newSession);
    }
  }, [getClientData]);

  const value = useMemo(
    () => ({
      session,
      handleLogin,
      logout,
      loadingCompany,
      company,
      companyError,
      loadingClient,
      client, 
    }),
    [session, handleLogin, logout, loadingCompany, company, companyError, loadingClient, client]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within as Authprovider");
  }

  return context;
}

const AVAILABLE_ROUTES = ["/dashboard"];

export function withAuth(Component: React.ComponentType) {
  return (props: any) => {
    const { session } = useAuth();
    const { pathname } = useLocation();

    if (session && !AVAILABLE_ROUTES.includes(pathname)) {
      return <Navigate to="/dashboard" replace />;
    }

    if (!session) {
      return <Navigate to="/" replace />;
    }

    return <Component {...props} />;
  };
}

export function withoutAuth(Component: React.ComponentType) {
  return (props: any) => {
    const { session } = useAuth();

    if (session) {
      return <Navigate to="/dashboard" replace />;
    }

    return <Component {...props} />;
  };
}
