import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getSubdomain = () => {
  const hostname = window.location.hostname
    .replace("www.", "")
    .replace(".com", "");
  const parts = hostname.split(".");

  let subdomain = "";

  if (parts.length > 1) {
    // If there are more than 2 parts, the first part is likely the subdomain
    subdomain = parts[0];
  }

  return subdomain;
};
