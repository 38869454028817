import Header from "@/components/Header";
import { Button } from "@/components/ui/Button";
import { toast } from "react-toastify";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/Select";
import { Switch } from "@/components/ui/Switch";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/Table";
import { useAuth, withAuth } from "@/contexts/authContext";
import { Lead } from "@/dtos/leads";
import { formatDate, formatPhone } from "@/lib/format";
import api from "@/services/api";
import { Phone, Trash } from "lucide-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Input } from "@/components/ui/Input";
import Chat from "@/components/Chat";

const filterOptions = [
  { value: "1", label: "Contatos de hoje" },
  { value: "2", label: "Últimos 2 dias" },
  { value: "3", label: "Últimos 3 dias" },
  { value: "4", label: "Últimos 4 dias" },
  { value: "5", label: "Últimos 5 dias" },
  { value: "6", label: "Últimos 6 dias" },
  { value: "7", label: "Última semana" },
  { value: "15", label: "Últimos 15 dias" },
  { value: "30", label: "Último mês" },
  { value: "all", label: "Todos os contatos" },
];

const Dashboard: React.FC = () => {
  const { client, loadingClient } = useAuth();

  const [filter, setFilter] = useState("1");
  const [search, setSearch] = useState("");

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingToggleBotState, setLoadingToggleBotState] = useState(false);
  const [loadingDeleteLead, setLoadingDeleteLead] = useState(false);

  const [leads, setLeads] = useState<Lead[]>([]);

  const loadLeads = useCallback(
    async (shouldLoad = true) => {
      setError(false);

      if (shouldLoad) {
        setLoading(true);
        setLeads([]);
      }

      const params = filter === "all" ? {} : { daysAgo: filter };

      try {
        const { data } = await api.get<Lead[]>(`/leads/${client?.phoneId}`, {
          params,
        });
        setLeads(data);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [client?.phoneId, filter]
  );

 

  const deleteLead = async (leadId: string) => {
    setLoadingDeleteLead(true);
    try {
      await api.delete(`/leads/${leadId}`);
      toast.success("Contato deletado com sucesso!");
      loadLeads(false);
    } catch (error) {
      toast.error("Erro ao deletar contato. Tente novamente mais tarde");
    } finally {
      setLoadingDeleteLead(false);
    }
  };

  const toggleBotState = async (leadId: string) => {
    setLoadingToggleBotState(true);
    try {
      await api.patch(`/leads/toggle-bot-state/${leadId}`);
      toast.success("Estado do robô alterado com sucesso!");
      loadLeads(false);
    } catch (error) {
      toast.error("Erro ao alterar estado do robô. Tente novamente mais tarde");
    } finally {
      setLoadingToggleBotState(false);
    }
  };

  const leadsToList = useMemo(() => {
   if (!search) {
    return leads;
   }

   return leads.filter((lead) => lead.name.toLowerCase().includes(search.toLowerCase()) || lead.phone.includes(search))
  }, [leads, search]);

  useEffect(() => {
    if (!loadingClient && client) {
      loadLeads();
    }
  }, [client, loadLeads, loadingClient]);

  return (
    <div>
      <Header />
      <div className="py-2 px-4 sm:px-6 lg:px-8 mt-8">
        <div className={`xl:container mx-auto`}>
          <div className="flex flex-col md:flex-row items-start md:items-end justify-between">
            <div>
              <div className="flex items-center mb-1">
                <Phone size={12} className="text-gray mr-2" />
                <p className="text-gray text-xs font-bold">
                  {formatPhone(client?.phone || "")}
                </p>
              </div>
              <h1 className="text-2xl font-bold">Seus contatos:</h1>
            </div>

            <Input
              placeholder="Pesquise por nome ou telefone"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              className="md:ml-auto md:mr-4 max-w-[300px] flex-1 mt-4 md:mt-0"
            />

            <Select value={filter} onValueChange={(value) => setFilter(value)}>
              <SelectTrigger className="max-w-[300px] mt-4 md:mt-0 flex-1">
                <SelectValue placeholder="Filtrar por" />
              </SelectTrigger>
              <SelectContent>
                {filterOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {error && (
            <div className="flex items-center flex-col justify-center mt-24">
              <h1 className="text-xl font-bold text-text text-center">
                Ops! Ocorreu um erro ao carregar os contatos. <br /> Tente
                novamente.
              </h1>
              <Button
                className="mt-4"
                variant="outline"
                onClick={() => loadLeads()}
              >
                Tentar novamente
              </Button>
            </div>
          )}

          {!error && (
            <Table className="mt-8">
              {leads.length === 0 && !loading && (
                <TableCaption className="mb-8">
                  Nenhum contato encontrado para o filtro selecionado
                </TableCaption>
              )}
              {(loadingClient || loading) && (
                <TableCaption className="mb-8">
                  Carregando contatos...
                </TableCaption>
              )}
              <TableHeader>
                <TableRow>
                  <TableHead className="min-w-[200px]">Nome</TableHead>
                  <TableHead className="min-w-[200px]">Telefone</TableHead>
                  <TableHead className="text-center">Estado</TableHead>
                  <TableHead className="min-w-[200px]">
                    Primeira conversa
                  </TableHead>
                  <TableHead className="min-w-[200px]">
                    Ultima conversa
                  </TableHead>
                  <TableHead className="min-w-[200px] text-center">
                    Ações
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody
                className={`${
                  loadingToggleBotState || loadingDeleteLead
                    ? "opacity-50 pointer-events-none"
                    : ""
                }`}
              >
                {leadsToList.map((lead) => (
                  <TableRow key={lead._id}>
                    <TableCell>{lead.name}</TableCell>
                    <TableCell>{formatPhone(lead.phone)}</TableCell>
                    <TableCell>
                      <div className="flex items-center flex-col justify-center">
                        <Switch
                          className={`${
                            !lead.isBotAnswering &&
                            lead.isBotPausedByHuman &&
                            "data-[state=unchecked]:bg-yellow"
                          }`}
                          checked={lead.isBotAnswering}
                          onCheckedChange={() => toggleBotState(lead._id)}
                        />
                        <span className="text-xs text-gray text-center mt-2">
                          {!lead.isBotAnswering && lead.isBotPausedByHuman
                            ? "Robô pausado"
                            : lead.isBotAnswering
                            ? "Robô ligado"
                            : "Robô desligado"}
                          <br />
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>{formatDate(lead.created_at)}</TableCell>
                    <TableCell>
                      {formatDate(lead.lastInteraction, true)}
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center justify-center gap-2">
                        <Chat lead={lead} />

                        <Button
                          variant="ghost"
                          onClick={() => deleteLead(lead._id)}
                        >
                          <Trash size={16} className="text-red" />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    </div>
  );
};

export default withAuth(Dashboard);
