import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "@/pages/Login";
import { useAuth } from "./contexts/authContext";
import { LoaderCircle } from "lucide-react";
import { Button } from "./components/ui/Button";
import Dashboard from "./pages/Dashboard";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
]);

function App() {
  const { company, loadingCompany, companyError } = useAuth();

  if (companyError) {
    return <div className="flex items-center flex-col justify-center min-h-screen">
      <h1 className="text-xl font-bold text-text text-center">Ops! Ocorreu um erro ao carregar o sistema. <br /> Tente recarregar a página.</h1>
      <Button className="mt-4" variant="default" onClick={() => window.location.reload()}>Recarregar página</Button>
    </div>
  }

  if (loadingCompany || !company) return (
    <div className="flex items-center flex-col justify-center min-h-screen">
      <LoaderCircle className="animate-spin text-text" size={32} />
      <h1 className="text-xl font-bold text-text">Carregando...</h1>
    </div>
  );

  return (
    <RouterProvider router={router} />
  );
}

export default App;
